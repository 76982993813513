
import { throwError as observableThrowError, throwError } from 'rxjs';

import { ErrorHandler, Injectable, Inject, Injector } from '@angular/core';
// import { Http} from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

// import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppHttpService extends ErrorHandler {
    public hostUrl = environment.baseUrl;
    public hostcount = 0;
    // public apiHost = environment.GOOGLE_URL;
    constructor(
        // public http: Http,
        @Inject(Injector) private injector: Injector,
        public TokenStorageService: TokenStorageService, public httpClient: HttpClient,
        private router: Router) {
            super();
         }
    getHeader(headerOptions: any, qparams: any = null, doNotSendAuthorizationParam: any) {
        let headerParams: any = {};
        let companyname = localStorage.getItem('company_name');
        if (companyname == '') {
            companyname = localStorage.getItem('company_url');
        }
        // console.log('Service Company Name : ' + companyname);
        if (companyname != '') {
            // For Localhost API
            /* let companyUrl = companyname + ".localhost";
            if (this.hostcount < 1) {
                this.hostUrl = this.hostUrl.replace('localhost', companyUrl);
            }
            this.hostcount = this.hostcount + 1; */
            // For Server API
            this.hostUrl = this.hostUrl.replace('tenantname', companyname);
        }
        if (doNotSendAuthorizationParam !== true && this.TokenStorageService.getToken()) {
            //send authorization param
            headerParams['x-auth-token'] = this.TokenStorageService.getToken();
            headerParams['Authorization'] = 'Bearer ' + this.TokenStorageService.getToken();
            headerParams['X-CSRF-TOKEN'] = this.TokenStorageService.getToken();
        }
        if (headerOptions) {
            headerParams = { ...headerParams, ...headerOptions }
        }
        let params: HttpParams = new HttpParams();
        for (let key in qparams) {
            params.set(key, qparams[key]);
        }
        let headers = new HttpHeaders(headerParams);
        return headers;
    }

    get(url: any, apiHost: any = null, params: any = null, headerOptions: any = null, doNotSendAuthorizationParam: boolean = false) {

        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        if (apiHost == null) {
            apiHost = this.hostUrl;
        }
        return this.httpClient.get<any>(apiHost + url, { params: params, headers: httpOptions }).pipe(map(data => {
            if (data) {

                return data
            } else {
                return []
            }
        }), tap(),
            catchError(this.handleError)

        )
    }

    post(url: any, params: any = null, apiHost: any = null, responseType: any = null, headerOptions: any = null, doNotSendAuthorizationParam: boolean = false) {
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        if (apiHost == null) {
            apiHost = this.hostUrl;
        }
        return this.httpClient.post<any>(apiHost + url, params, { headers: httpOptions, ...responseType }).pipe(map(data => {
            if (data) {

                return data
            } else {
                return []
            }
        }), tap(),
            catchError(this.handleError)


        )
    }

    put(url: any, params: any = null, headerOptions: any = null, doNotSendAuthorizationParam: boolean = false) {
        let httpOptions = this.getHeader(headerOptions, params, doNotSendAuthorizationParam);
        return this.httpClient.put<any>(this.hostUrl + url, params, { headers: httpOptions }).pipe(map(data => {
            if (data) {

                return data
            } else {
                return []
            }
        }), tap(), 
            catchError(this.handleError)
        )
    }

    delete(url: any, apiHost: any = null, headerOptions: any = null, doNotSendAuthorizationParam: boolean = false) {
        if (apiHost == null) {
            apiHost = this.hostUrl;
        }
        let httpOptions = this.getHeader(headerOptions, {}, doNotSendAuthorizationParam);
        return this.httpClient.delete<any>(apiHost + url, { headers: httpOptions })
            .pipe(map(data => {
                if (data) {

                    return data
                } else {
                    return []
                }
            }), tap(), 
                catchError(this.handleError)
            )
    }

    public handleError(error: HttpErrorResponse) {
        // return an observable with a user-facing error message
        //this.toastrService.error("testing", null, { onActivateTick: true });
        if (error.status == 403 || error.status == 401 || error.status == 500) {
            //console.log('HTTP Error:', error);
            let companyname = localStorage.getItem('company_name');
            if (companyname == '' || companyname == null) {
                companyname = localStorage.getItem('company_url');
            }
            location.replace('#/'+companyname+'/login');
            localStorage.clear();
            /* this.toastr.error('Token is Expired, please login again');
            this.router.navigate([companyname+'/login']); */
        } 
        return throwError(error.error);

    };

    private get toastrService(): ToastrService {
        return this.injector.get(ToastrService);
    }
}
