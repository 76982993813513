import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { AngularMaterialModule } from './shared/modules/angular-material/angular-material.module';

import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { AppHttpService } from './shared/services/app-http.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import { AngularOtpLibModule } from 'angular-otp-box';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        AngularOtpLibModule,
        AngularMaterialModule,
        ToastrModule.forRoot(
            {
                preventDuplicates: true
            }
        ),
        DataTablesModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        NgbModule.forRoot(),
        CKEditorModule
        
    ],
    declarations: [AppComponent],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
