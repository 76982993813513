import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppHttpService } from 'src/app/shared/services/app-http.service';
import * as decode from 'jsonwebtoken';
let baseUrl = environment.baseUrl;
@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(private http: AppHttpService) {
    }
    SignupIndustry(){
        return this.http.get('/industrytypelist', baseUrl);
    }
    registerUser(user) {
       return this.http.post('/m_d_register', user, baseUrl);
    }
    loginUser(user) {
        return this.http.post('/vendorlogin', user);
    }
    loginLogo() {
        return this.http.get('/company');
    }
    tenantValid(data) {
        return this.http.post('/tenant_check', data);
    }
    otpVerify(data) {
        return this.http.post('/vendorverifypassword', data);
    }
    verifyPassword(data, token) {
        return this.http.post('/user/vendorresetpassword', data, null, { 'Authorization' : 'Bearer ' + token  });
    }
    sendOtp(data) {
        return this.http.post('/sendotp', data);
    }
    getCompanyIdUser(data) {
        return this.http.post('/findcompany', data);
    }
    getFullToken() {
        return localStorage.getItem("atapi_token");
    }
    getToken() {
        return !!localStorage.getItem("atapi_token");
    }
    sendEmail(user) {
        return this.http.post('/forgetpassword/send', user);
    }
    resetPassword(user) {
        return this.http.post('/forgotpassword/update', user);
    }
    changeUserPassword(user) {
        return this.http.post('/user/change_password', user);
      }
    
}
