// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

export const environment = {
  env:'dev',
  production: false,
  baseUrl: 'http://tenantname.erpone.solutions/api',
  sourceurl: "http://tenantname.erpone.solutions/",
  socketUrl: "wss://socket.erpone.app/map-data/dev/",
  originUrl: "http://www.erpone.solutions",
  webSocketUrl: "wss://socket.erpone.solutions/chat/tenantname/",
  promotionWebSocketUrl: "wss://socket.erpone.solutions/chat/promotion-chat/tenantname/",
  AWS_ACCESS_KEY_ID: 'AKIAV7JNPSGT3IZSPICS',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'ap-south-1'

  /* baseUrl: 'http://localhost:8000/api',
  sourceurl: "http://localhost:8000/" */
};
// export const environment = {
//   env:'uat',
//   production: true,
//   baseUrl: 'http://tenantname.erpone.biz/api',
//   sourceurl: "http://tenantname.erpone.biz/",
//   socketUrl: "wss://socket.erpone.app/map-data/uat/",
//   originUrl: "http://www.erpone.biz",
//   webSocketUrl: "wss://socket.erpone.solutions/chat/tenantname/",
//   promotionWebSocketUrl: "wss://socket.erpone.solutions/chat/promotion-chat/tenantname/",
//   AWS_ACCESS_KEY_ID: 'AKIAV7JNPSGT3IZSPICS',
//   AWS_SECRET_ACCESS_KEY: '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',
//   AWS_REGION: 'ap-south-1'
// };


/*
13.127.0.149
13.127.0.149
127.0.0.1:8000


3.16.154.182/api/atapi
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
